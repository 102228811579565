import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Col,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loadProfile, saveProfile } from '../../src/actions/ProfileActions';
import { loadPlans } from '../../src/actions';
import { redirect } from '../../src/actions/UIActions';
import ProfileNotifications from '../../src/components/ProfileNotifications';

const disabled = {
  inputProps: { disabled: true },
};

const fields = [
  [
    {
      title: 'Salutation',
      field: 'salutation',
      type: 'text',
      colSize: 'auto',
      inputProps: { size: '4' },
    },
  ],
  [
    {
      title: 'First Name',
      field: 'firstName',
      type: 'text',
    },
    {
      title: 'Last Name',
      field: 'lastName',
      type: 'text',
    },
  ],
  [
    { title: 'Email', field: 'email', type: 'email', ...disabled },
    { title: 'Job Title', field: 'jobTitle', type: 'text' },
  ],
  [
    { title: 'Direct Phone Number', field: 'phoneDirect', type: 'tel' },
    { title: 'Switchboard Number', field: 'phoneMain', type: 'tel' },
    { title: 'Mobile Number', field: 'phoneMobile', type: 'tel' },
  ],
  [{ title: 'Street', field: 'street1', type: 'text' }],
  [
    { title: 'City', field: 'city', type: 'text' },
    { title: 'State/Province', field: 'stateOrProvince', type: 'text' },
    { title: 'Zip/Postal Code', field: 'postalCode', type: 'text' },
  ],
  [
    {
      title: 'Country/Region',
      field: 'country',
      type: 'text',
      colSize: 4,
    },
  ],
];

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      contactId: props.profile.contactId,
      profile: {},
      notifications: {},
      clientHubWeeklyActivity: props.profile.clientHubWeeklyActivity,
    };
    this.loadProfile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.contactId !== this.state.contactId) {
      this.setState({
        profile: {},
        notifications: {},
        contactId: nextProps.profile.contactId,
        clientHubWeeklyActivity: nextProps.profile.clientHubWeeklyActivity,
      });
    }
    this.loadProfile();
  }

  loadProfile = _.debounce(() => {
    if (this.props.email) {
      this.props.loadProfile();
      this.props.loadPlans(this.props.email, this.props.isEmployee);
    } else {
      this.loadProfile();
    }
  }, 1000);

  renderFormInputs = () => {
    const editedProfile = this.state.profile;
    const originalProfile = this.props.profile;
    return _.map(fields, (row, rowIndex) => {
      const inputs = _.map(row, field => {
        const fieldName = field.field;
        const value =
          editedProfile[fieldName] || originalProfile[fieldName] || '';
        const groupProps = field.groupProps || {};
        const labelProps = field.labelProps || {};
        const inputProps = field.inputProps || {};
        const colProps = field.colProps || {};
        const onChange = ({ target: { value } }) => {
          this.setState({
            profile: {
              ...this.state.profile,
              [fieldName]: value,
            },
          });
        };
        const size = field.colSize || 12 / row.length;
        const label = (
          <Label for={fieldName} key={'label' + fieldName} {...labelProps}>
            {field.title}
          </Label>
        );
        const input = (
          <Input
            key={'input' + fieldName}
            id={fieldName}
            type={field.type}
            value={value}
            readOnly={!this.state.editing}
            disabled={field.type === 'checkbox' && !this.state.editing}
            onChange={onChange}
            {...inputProps}
          />
        );
        let items = [label, input];
        if (field.type === 'checkbox') {
          items = [input, label];
        }
        return (
          <Col key={fieldName} md={size} {...colProps}>
            <FormGroup {...groupProps}>{items}</FormGroup>
          </Col>
        );
      });
      return <Row key={rowIndex}>{inputs}</Row>;
    });
  };

  renderWeeklyCheckBox = () => {
    const onChange = () => {
      this.setState({
        clientHubWeeklyActivity: !this.state.clientHubWeeklyActivity,
      });
    };
    return (
      <div>
        <div className="ManagerNotificationTittle">
          <Col sm={12} md={12}>
            <h5>Manager Activity Notifications</h5>
            <p>
            Select this option to receive a weekly summary of new Manager Activities and NEPC Insights.
            </p>
          </Col>
        </div>
        <row>
          <div className="weeklyInput">
            <div className="col-sm-12 col-md-6">
              <Input
                key="inputWeeklyNotify"
                id="weeklyNotifications"
                isChecked={`${this.state.clientHubWeeklyActivity}`}
                type="checkbox"
                readOnly={!this.state.editing}
                disabled={!this.state.editing}
                checked={this.state.clientHubWeeklyActivity}
                onChange={onChange}
              />
              <Label>ClientHUB Weekly Activity</Label>
            </div>
          </div>
        </row>
      </div>
    );
  };

  handleEdit = () => {
    if (this.state.contactId) {
      this.setState({ editing: true });
    }
  };

  handleSave = () => {
    this.setState({ editing: false }, () => {
      const sendDocumentNotifications = _.reduce(
        _.values(this.state.notifications),
        (res, plan) => {
          return { ...res, [plan.planId]: plan.sendNotifications };
        },
        {}
      );
      const profile = {
        ...this.props.profile,
        ...this.state.profile,
        sendDocumentNotifications,
        clientHubWeeklyActivity: this.state.clientHubWeeklyActivity,
      };
      this.props.saveProfile(profile).then(() => {
        this.setState({ profile: {}, notifications: {} });
      });
    });
  };

  cancelAndRedirect = to => {
    this.setState({ profile: {}, notifications: {} }, () => {
      this.props.redirect(to);
    });
  };

  handleCancel = () => this.cancelAndRedirect('/portfolios');
  handleChangePassword = () => this.cancelAndRedirect('/changePassword');

  renderFormControlPrimary = () => {
    if (this.state.editing) {
      return (
        <Button className="btn-primary" onClick={this.handleSave}>
          Save
        </Button>
      );
    } else {
      return (
        <Button className="btn-primary" onClick={this.handleEdit}>
          Edit
        </Button>
      );
    }
  };

  renderFormControl() {
    return (
      <FormGroup row>
        <Col>
          {this.renderFormControlPrimary()}
          <Button
            className="ml-2"
            color="secondary"
            onClick={this.handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className="float-right"
            color="primary"
            onClick={this.handleChangePassword}>
            Change Password
          </Button>
        </Col>
      </FormGroup>
    );
  }

  render() {
    if (this.props.isEmployee) {
      return this.handleCancel();
    }
    return (
      <Container fluid className="content profile">
        <Container>
          <Form>
            {this.renderFormControl()}
            <h5>Profile</h5>
            {this.renderFormInputs()}
            {this.renderWeeklyCheckBox()}
            <hr />
            <ProfileNotifications
              editing={this.state.editing}
              notifications={this.state.notifications}
              onUpdate={notifications => this.setState({ notifications })}
              planNotifications={this.props.notifications}
            />
          </Form>
        </Container>
      </Container>
    );
  }
}

Profile.propTypes = {
  loadProfile: PropTypes.func.isRequired,
  loadPlans: PropTypes.func.isRequired,
  saveProfile: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  email: PropTypes.string,
  isEmployee: PropTypes.bool,
  profile: PropTypes.object,
  notifications: PropTypes.array,
  clientHubWeeklyActivity: PropTypes.bool,
};

const mapStateToProps = state => {
  const email = _.get(state, 'okta.user.email', '') || '';
  const plans = {
    ...state.resources.plans.data,
  };
  const notifications = _.chain(plans)
    .filter(plan => plan.accessEnabled)
    .map(plan => {
      const { planId, sendNotifications, planName } = plan;
      return {
        planId,
        sendNotifications,
        planName,
      };
    })
    .value();
  return {
    email,
    isEmployee: state.okta.isEmployee,
    profile: state.resources.profile.data || {},
    notifications,
  };
};

export default connect(mapStateToProps, {
  loadProfile,
  saveProfile,
  redirect,
  loadPlans,
})(Profile);
